<template>
    <div id="build-details-form">
        <div class="top">
            <div class="top__left">
                <h2 class="mb-3">
                    Connect Pinterest Assets
                </h2>
                <p class="desc">
                    Looking good! To deploy playbooks with Pinterest you'll need to connect the following assets from your
                    Pinterest account. This requires that you have each setup correctly so we highly encourage you to read
                    our guide.
                </p>
            </div>
            <div class="top-right">
                <action-button
                    color="#00A4EB"
                    width="55"
                    height="55"
                    href="https://support.buyerbridge.com/knowledge/create-assets-pinterest-onboarding"
                    target="_blank"
                    icon="book">
                    <div class="dark-word">
                        Read our<br>guide
                    </div>
                </action-button>
            </div>
        </div>

        <v-form
            ref="form"
            v-model="formValid">
            <div class="build-details">
                <loader v-if="loading" />
                <div v-else>
                    <div
                        v-if="errorMessage"
                        class="err-message">
                        {{ errorMessage }}
                    </div>
                    <div
                        v-if="boardError"
                        class="err-message">
                        {{ boardError }}
                    </div>

                    <table>
                        <tr v-if="!adAccountComplete">
                            <th />

                            <th>
                                <div class="d-flex">
                                    <div>
                                        <p class="mb-0 font-weight-regular">
                                            New
                                        </p>
                                        <a
                                            href="#"
                                            class="d-block font-weight-regular"
                                            @click.prevent="selectAll('new')">
                                            select all
                                        </a>
                                    </div>

                                    <div>
                                        <p class="mb-0 font-weight-regular">
                                            Existing
                                        </p>
                                        <a
                                            href="#"
                                            class="d-block font-weight-regular"
                                            @click.prevent="selectAll('existing')">
                                            select all
                                        </a>
                                    </div>
                                </div>
                            </th>

                            <th />
                        </tr>

                        <!-- AD ACCOUNT -->
                        <tr v-if="hasAdAccountSetup">
                            <td>
                                <h2>Ad Account</h2>

                                <p class="desc">
                                    Choose the ad account in Pinterest you want to associate with
                                    {{ dealer.name }}
                                    and ensure it has billing setup.
                                </p>
                            </td>

                            <td>
                                <v-radio-group
                                    v-if="!adAccountComplete"
                                    v-model="newAdAccount"
                                    class="justify-center">
                                    <div class="layout">
                                        <v-radio
                                            :value="true"
                                            color="primary"
                                            class="radio-button-inside mb-0" />
                                        <v-radio
                                            :value="false"
                                            color="primary"
                                            class="radio-button-inside mb-0" />
                                    </div>
                                </v-radio-group>

                                <div v-else />
                            </td>

                            <td>
                                <action-button
                                    v-if="accountLoading"
                                    size="35"
                                    icon=""
                                    :loading="true" />
                                <div
                                    v-if="!accountLoading && !adAccountComplete"
                                    class="block-select">
                                    <v-select
                                        v-model="account"
                                        :disabled="newAdAccount"
                                        :rules="newAdAccount ? [] : requiredRules"
                                        placeholder="Select Account..."
                                        :items="accounts"
                                        label="AD ACCOUNT"
                                        class="styled-field" />
                                </div>
                                <div v-if="adAccountComplete">
                                    <status-icon size="25" />
                                    <span class="pl-2">Configured!</span>
                                </div>
                            </td>
                        </tr>

                        <!-- PIXEL -->
                        <tr v-if="hasPixelSetup">
                            <td>
                                <h2>Pixel</h2>

                                <p class="desc">
                                    Choose the pixel in Pinterest you want to associate with {{ dealer.name }}
                                </p>
                            </td>

                            <td>
                                <v-radio-group
                                    v-if="!pixelComplete"
                                    v-model="newPixel"
                                    class="justify-center">
                                    <div class="layout">
                                        <v-radio
                                            :value="true"
                                            color="primary"
                                            class="radio-button-inside mb-0" />
                                        <v-radio
                                            :value="false"
                                            color="primary"
                                            class="radio-button-inside mb-0" />
                                    </div>
                                </v-radio-group>

                                <div v-else />
                            </td>

                            <td>
                                <action-button
                                    v-if="pixelLoading"
                                    size="35"
                                    icon=""
                                    :loading="true" />
                                <div
                                    v-if="!pixelLoading && !pixelComplete"
                                    class="block-select">
                                    <v-select
                                        v-model="pixel"
                                        :rules="newPixel ? [] : requiredRules"
                                        placeholder="Select pixel..."
                                        :items="pixels"
                                        :disabled="newAdAccount || newPixel"
                                        label="PIXEL"
                                        class="styled-field" />
                                </div>
                                <div v-if="pixelComplete">
                                    <status-icon size="25" />
                                    <span class="pl-2">Configured!</span>
                                </div>
                            </td>
                        </tr>
                    </table>

                    <!-- CATALOGS -->
                    <div v-if="hasCatalogSetup">
                        <h2 class="mt-5">
                            Catalog
                        </h2>
                        <div class="notice">
                            <div class="notice-icon">
                                <action-button
                                    width="25"
                                    height="25"
                                    :color="colors.yellow"
                                    icon="indicator-warning">
                                    <div class="dark-word">
                                        NOTICE
                                    </div>
                                </action-button>
                            </div>
                            <div class="notice-desc">
                                In order to run dynamic inventory ads on Pinterest,
                                you will need to submit your catalog to Pinterest for whitelisting.
                                Once submitted please allow up to 72 hours for Pinterest to respond.
                            </div>
                            <div class="notice-button">
                                <a
                                    class="notice-link"
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSd-62qyx31kghbF05Y00bO584SVxHJkEw6GrrV2oBvvIwvPWQ/viewform"
                                    target="_blank">
                                    COMPLETE WHITELISTING FORM
                                </a>
                            </div>
                        </div>
                        <div class="block">
                            <div class="block-info">
                                <p class="desc">
                                    Choose the catalog in Pinterest you want to associate with {{ dealer.name }}
                                </p>
                            </div>
                            <action-button
                                v-if="catalogsLoading"
                                size="35"
                                icon=""
                                :loading="true" />
                            <div
                                v-if="!catalogsLoading && !catalogComplete"
                                class="block-select">
                                <v-select
                                    v-model="catalog"
                                    :rules="requiredRules"
                                    placeholder="Select catalog..."
                                    :items="catalogs"
                                    :disabled="!accountId"
                                    label="CATALOG"
                                    class="styled-field" />
                            </div>
                            <div v-if="catalogComplete">
                                <status-icon size="25" />
                                <span class="pl-2">Configured!</span>
                            </div>
                            <div class="block-icons">
                                <action-button
                                    v-if="!catalogComplete"
                                    class="icon"
                                    width="23"
                                    height="23"
                                    icon="refresh"
                                    :disabled="!accountId"
                                    @click.prevent="getCatalogs">
                                    <div class="dark-word sp">
                                        REFRESH
                                    </div>
                                </action-button>
                                <action-button
                                    v-if="!catalogComplete"
                                    class="icon"
                                    width="25"
                                    height="25"
                                    href="https://support.buyerbridge.com/knowledge/create-assets-pinterest-onboarding#pinterest-catalog"
                                    target="_blank"
                                    icon="book"
                                    @click.prevent="">
                                    <div class="dark-word">
                                        GUIDE
                                    </div>
                                </action-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="hasCatalogSetup">
                    <div class="url-field">
                        <p class="desc mb-2">
                            During setup you'll need the feed URL below:
                        </p>
                        <div class="field-holder">
                            <action-button
                                v-if="urlLoading"
                                size="35"
                                icon=""
                                :loading="true" />
                            <v-text-field
                                v-else
                                ref="urlInput"
                                v-model="url"
                                readonly
                                class="styled-field" />
                            <action-button
                                v-if="!copied"
                                class="copy"
                                width="27"
                                height="27"
                                icon="copy"
                                @click.prevent="copy" />
                            <span
                                v-else
                                class="copied">Copied!</span>
                        </div>
                    </div>
                    <a
                        href="#"
                        class="trouble"
                        @click.prevent="$store.dispatch('createOnboardingSupportTicket')">Having trouble? Connect with support</a>
                </div>
            </div>
            <div class="button-wrapper">
                <styled-button
                    v-if="submitInProgress"
                    class="styled-button"
                    :disabled="true"
                    :loading="submitInProgress"
                    @click.prevent="submit">
                    WAIT...
                </styled-button>
                <styled-button
                    v-else
                    class="styled-button"
                    :disabled="!formValid"
                    @click.prevent="submit">
                    CONTINUE
                </styled-button>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ActionButton from '@/components/globals/ActionButton';
import StyledButton from '@/components/globals/StyledButton';
import validationRules from '@/helpers/validationRules';
import Loader from '@/components/globals/Loader';
import StatusIcon from '@/components/globals/StatusIcon';
import colors from '@/helpers/colors';
import { PLATFORM_ID_PINTEREST, PLATFORM_PINTEREST } from '@/helpers/globals';

    export default {
        components: {
            ActionButton,
            StyledButton,
            Loader,
            StatusIcon
        },
        data() {
            return {
                colors,
                requiredRules: validationRules.requiredRules,
                formValid: false,

                boardId: '',
                boardError: '',

                account: '',
                accounts: [],
                accountId: '',
                accountOwner: '',
                accountsData: [],
                accountLoading: false,

                pixel: '',
                pixels: [],
                pixelId: '',
                pixelCode: '',
                pixelsData: [],
                pixelLoading: false,

                catalog: '',
                catalogs: [],
                catalogId: '',
                catalogsData: [],
                catalogsLoading: false,

                url: '',
                urlLoading: true,
                copied: false,
                loading: true,
                errorMessage: '',
                submitInProgress: false,

                newAdAccount: true,
                newPixel: true,
            };
        },
        computed: {
            ...mapState({
                onboardingProducts: (state) => state.onboarding.products,
                dealer: (state) => state.dealer.currentDealer,
                currentAgency: (state) => state.agency.currentAgency,
            }),
            ...mapGetters([
                'dealerFeaturesComplete',
                'dealerFeaturesSetup',
                'requiredFeatures',
                'onboardingStatus'
            ]),
            hasAdAccountSetup() {
                return this.requiredFeatures.includes('pinterest_ad_account');
            },
            adAccountComplete() {
                return this.dealerFeaturesComplete.includes('pinterest_ad_account');
            },
            hasPixelSetup() {
                return this.requiredFeatures.includes('pinterest_pixel');
            },
            pixelComplete() {
                return this.dealerFeaturesComplete.includes('pinterest_pixel');
            },
            hasCatalogSetup() {
                return this.requiredFeatures.includes('pinterest_catalog');
            },
            catalogComplete() {
                return this.dealerFeaturesSetup.includes('pinterest_catalog');
            },
            allAssetsSetup() {
                return (
                    (this.hasAdAccountSetup ? !this.adAccountSetupIncomplete : true) &&
                    (this.hasPixelSetup ? !this.pixelSetupIncomplete : true) &&
                    (this.hasCatalogSetup ? this.catalogComplete : true)
                );
            },
            adAccountSetupIncomplete() {
                if (!this.hasAdAccountSetup) {
                    return null;
                }
                const adAccountStatus = this.onboardingStatus.find(feature => feature.feature === 'pinterest_ad_account');

                if (!adAccountStatus) {
                    return true;
                }

                return ['missing_initial_setup', 'missing_dependencies'].includes(adAccountStatus.status);
            },
            pixelSetupIncomplete() {
                if (!this.hasPixelSetup) {
                    return null;
                }

                const pixelStatus = this.onboardingStatus.find(feature => feature.feature === 'pinterest_pixel');

                if (!pixelStatus) {
                    return true;
                }

                return ['missing_initial_setup', 'missing_dependencies'].includes(pixelStatus.status);
            },
            localRequiredFeatures() {
                const localFeatures = [
                    'pinterest_ad_account',
                    'pinterest_pixel',
                    'pinterest_catalog'
                ];

                return this.requiredFeatures.filter(feature => (
                    localFeatures.includes(feature)
                ));
            },
            valid() {
                return (
                    ((this.hasAdAccountSetup && !this.adAccountComplete) ? this.account : true) &&
                    ((this.hasPixelSetup && !this.pixelComplete) ? this.pixel : true) &&
                    ((this.hasCatalogSetup && !this.catalogComplete) ? this.catalog : true)
                );
            },
        },
        watch: {
            account() {
                if (this.account) {
                    const currentAccount = this.accountsData.filter(e => e.name == this.account);
                    this.accountOwner = currentAccount[0].owner;
                    this.accountId = currentAccount[0].id;
                    this.getPixel();
                    this.getCatalogs();
                    this.getBoards();
                }
            },
            catalog() {
                if (this.catalog) {
                    const currentCatalog = this.catalogsData.filter(e => e.name == this.catalog);
                    this.catalogId = currentCatalog[0].id;
                }
            },
            pixel() {
                if (this.pixel) {
                    const currentPixel = this.pixelsData.filter(e => e.name == this.pixel);
                    this.pixelId = currentPixel[0]?.id;
                }
            }
        },
        async mounted() {
            await this.initAll();
        },
        methods: {
            async initGeneratingRemoteFiles() {
                const fileId = {
                    export_format_ids: []
                };
                if (this.onboardingProducts[0].platform_id == 10) {
                    fileId.export_format_ids = [15];
                }
                try {
                    await this.$http.post(`/dealers/${this.dealer.id}/upload-exports`, fileId);
                } catch(error) {
                    console.log(error);
                }
            },
            async initAll() {
                if (this.hasCatalogSetup) {
                    await this.initGeneratingRemoteFiles();
                }

                this.getAdAccounts();

                if (this.adAccountComplete) {
                    const currentAccount = this.dealer.ad_accounts.data.find(account => account.platform_id == PLATFORM_ID_PINTEREST);
                    this.accountId = currentAccount.external_ad_account_id;
                    this.getPixel();
                    this.getCatalogs();
                }

                if (this.hasCatalogSetup) {
                    await this.getURL();
                }
            },
            async getBoards() {
                try {
                    this.boardError = '';
                    const boards = await this.$http.get(`/agencies/${this.currentAgency.id}/platforms/pinterest/boards?ad_account_id=${this.accountId}`);
                    const availableBoard = boards.data.data.filter(board => {
                        return (board.name === 'Our Ads' && board.owner === this.accountOwner);
                    });
                    if (availableBoard.length) {
                        this.boardId = availableBoard[0].external_id;
                    } else {
                        await this.createNewBoard();
                    }
                } catch(error) {
                    this.boardError = 'Setup of Pinterest board has failed... Please contact support.';
                }
            },
            async getAdAccounts() {
                try {
                    this.accountLoading = true;
                    const response = await this.$http.get(`/agencies/${this.currentAgency.id}/platforms/pinterest/ad-accounts`);
                    this.accountsData = [];
                    this.accounts = [];
                    this.account = '';
                    this.pixel = '';
                    this.catalog = '';
                    response.data.data.forEach(e => {
                        this.accountsData.push({
                            'owner': e.owner,
                            'name': e.name,
                            'id': e.external_id
                        });
                        this.accounts.push(e.name);
                    });
                } catch(error) {
                    this.accountLoading = false;
                    console.log(error);
                    this.errorMessage = error;
                } finally {
                    this.accountLoading = false;
                    this.loading = false;
                }
            },
            async getPixel() {
                try {
                    this.pixelLoading = true;
                    const response = await this.$http.get(`/agencies/${this.currentAgency.id}/platforms/pinterest/pixels?ad_account_id=${this.accountId}`);
                    this.pixels = [];
                    this.pixelsData = [];
                    this.pixel = '';
                    response.data.data.forEach(e => {
                        this.pixelsData.push({
                            'name': e?.name,
                            'id': e?.external_id?.toString(),
                        });
                        this.pixels.push(e.name);
                    });
                } catch(error) {
                    this.pixelLoading = false;
                    console.log(error);
                    this.errorMessage = error;
                } finally {
                    this.pixelLoading = false;
                }
            },
            async getCatalogs() {
                this.catalogsLoading = true;
                try {
                    const response = await this.$http.get(`/agencies/${this.currentAgency.id}/platforms/pinterest/catalogs?ad_account_id=${this.accountId}`);
                    this.catalogs = [];
                    this.catalogsData = [];
                    this.catalog = '';
                    response.data.data.forEach(e => {
                        this.catalogsData.push({
                            'name': e.name,
                            'id': e.external_id
                        });
                        this.catalogs.push(e.name);
                    });
                } catch(error) {
                    this.catalogsLoading = false;
                    console.log('Error:', error);
                    this.errorMessage = error;
                } finally {
                    this.catalogsLoading = false;
                }
            },
            async getURL() {
                try {
                    this.urlLoading = true;
                    const response = await this.$http.get(`/dealers/${this.dealer.id}/get-remote-files`);
                    const remoteFiles = response.data;
                    remoteFiles.forEach(file =>{
                        if (file.remote_file_type.name.includes('Pinterest')) {
                            this.url = file.url;
                        }
                    });
                } catch(error) {
                    console.log(error);
                } finally {
                    if (this.url) {
                        this.urlLoading = false;
                    } else {
                        setTimeout(() => {
                            this.getURL();
                        }, 10000);
                    }
                }
            },
            async sendAllData() {
                if (
                    (this.hasAdAccountSetup && this.adAccountSetupIncomplete)
                    && this.newAdAccount
                ) {
                    await this.createNewAdAccount();
                    console.log('A new ad account is setup!');
                }
                else if (
                    (this.hasAdAccountSetup && this.adAccountSetupIncomplete)
                    && !this.newAdAccount
                ) {
                    await this.connectExistingAdAccount();
                    console.log('An existing ad account is connected!');
                }


                if (this.hasPixelSetup && this.pixelSetupIncomplete && this.newPixel) {
                    await this.createNewPixel();
                    this.loadingConnectionsMessage = 'Congrats!  You\'re now the owner of a fresh new pixel';
                }
                else if (this.hasPixelSetup && this.pixelSetupIncomplete && !this.newPixel) {
                    await this.connectExistingPixel();
                    this.loadingConnectionsMessage = 'Ad violia!  We just hooked up your exsting pixel.';
                }

                if (this.hasCatalogSetup && !this.catalogComplete) {
                    await this.createNewCatalog();
                }

                await this.checkFeatureStatus();
            },
            checkFeatureStatus() {
                return this.$store.dispatch('updateOnboardingStatus', {
                    features: this.localRequiredFeatures
                });
            },
            async createNewBoard() {
                const boardData = {
                    name: 'Our Ads',
                };
                try {
                    this.boardError = '';
                    const newBoard = await this.$http.post(`/agencies/${this.currentAgency.id}/platforms/pinterest/boards?ad_account_id=${this.accountId}`, boardData);
                    this.boardId = newBoard.data.id;
                } catch(error) {
                    this.boardError = 'Setup of Pinterest board has failed... Please contact support.';
                }
            },
            async connectExistingAdAccount() {
                const adAccountData = {
                    dealer_id: this.dealer.id,
                    platform_id: PLATFORM_ID_PINTEREST,
                    external_ad_account_id: this.accountId,
                    configuration: {
                        pinterest_board: {
                            id: this.boardId
                        }
                    }
                };
                try {
                    const newAdAccount = await this.$apiRepository.createDealerAdAccount(this.dealer.id, PLATFORM_ID_PINTEREST, adAccountData);
                    console.log(newAdAccount);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async createNewCatalog() {
                const catalogData = {
                    dealer_id: this.dealer.id,
                    platform_id: 10,
                    external_catalog_id: this.catalogId,
                };
                try {
                    const newCatalog = await this.$http.post('/catalogs', catalogData);
                    console.log(newCatalog);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async createNewPixel() {
                try {
                    await this.$apiRepository.createPinterestPixel(this.dealer.id);
                } catch(error) {
                    this.handleError('Error creating new Pinterest pixel', error);
                }
            },
            async connectExistingPixel() {
                const pixelData = {
                    dealer_id: this.dealer.id,
                    platform_id: 10,
                    external_pixel_id: this.pixelId,
                };
                try {
                    const newPixel = await this.$http.post('/pixels', pixelData);
                    console.log(newPixel);
                } catch(error) {
                    console.log(error);
                    this.errorMessage = error;
                }
            },
            async submit() {
                if (this.boardError) {
                    return;
                }
                if (this.allAssetsSetup) {
                    this.$store.dispatch('goToNextStep');
                    return;
                }

                this.$refs.form.validate();
                if (!this.formValid) {
                    console.log('Please provide all required data');
                    return;
                }

                this.submitInProgress = true;
                this.errorMessage = '';
                await this.sendAllData();

                if (this.allAssetsSetup) {
                    this.$store.dispatch('goToNextStep');
                    this.submitInProgress = false;
                    return;
                }

                if (this.errorMessage) {
                    this.submitInProgress = false;
                }
            },
            copy() {
                const copyText = this.$refs.urlInput;
                navigator.clipboard.writeText(copyText.value).then(() => {
                    this.copied = true;
                }).catch(err => {
                    console.log('Something went wrong with copying:', err);
                });
            },
            async createNewAdAccount() {
                try {
                    await this.$apiRepository.createAdAccount(this.dealer.id, PLATFORM_PINTEREST);
                } catch(error) {
                    this.handleError('Error creating new ad account', error);
                }
            },
            selectAll(type) {
                if (type === 'new') {
                    this.newAdAccount = true;
                    this.newPixel = true;
                } else {
                    this.newAdAccount = false;
                    this.newPixel = false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
.top {
    display: flex;
    justify-content: space-between;
}
.top-right {
    padding: 30px;
    min-width: 130px;
}
.desc {
    font-size:14px;
}
.build-details {
    padding: 30px;
    margin-top: 15px;
    border-top: 1px solid $gray-light;
}
.dark-word {
    color:$gray-dark;
    font-size: 10px;
    line-height: 14px;
}
.icon {
    margin-left: 30px;
}
.video-icon {
    path {
        transform: translateY(-2px);
    }
}
.block {
    display: flex;
    flex-wrap: wrap;
}
.block-icons {
    white-space: nowrap;
    width: 15%;
}
.block-info {
    width: 100%;
    margin-bottom: 50px;
    @media (min-width: $bp-lg) {
        width: 40%;
        margin-right: 5%;
        margin-bottom: 0;
    }
}
.block-select {
    //margin-right: 5%;
}
.button-wrapper {
    text-align: right;
    padding: 0 30px;
}
.styled-button {
    font-size: 14px;
}
.trouble {
    margin-top: 40px;
    display: inline-block;
    font-size: 14px;
}
.url-field {
    margin-top: 30px;
    max-width: 400px;
    input {
        padding-top: 17px;
        padding-bottom: 17px;
    }
}
.field-holder {
    display: flex;
}
.copy {
    margin-left: 15px;
    transform: translateY(2px);
}
.copied {
    color: green;
    margin-left: 10px;
    font-size: 14px;
    margin-top: 10px;
}
.err-message {
    padding: 0 30px;
    margin-bottom: 30px;
    text-align: center;
    color: red;
}
.sp {
    padding-top:2px;
}
.notice {
    display: flex;
    background-color: $yellow-bg;
    border: 1px solid $yellow-dark;
    padding: 15px;
    margin: 15px 0 25px 0;
}
.notice-icon {
    width: 8%;
    padding-left: 15px;
}
.notice-desc {
    width: 66%;
    display: flex;
    align-items: center;
    font-size: 13px;
    padding-right: 15px;
}
.notice-button {
    width: 26%;
    display: flex;
    align-items: center;
}
.notice-link {
    white-space: nowrap;
    text-decoration: none;
    font-weight: 600;
    color: $white;
    background: $yellow-btn;
    padding: 8px 15px;
    border-radius: 10px;
    font-size: 13px;
}

/*table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}*/
th, td {
    padding: 0.25rem;
}
th:first-child {
    width: 40%;
}
th:nth-child(2) {
    width: 20%;
}
th:last-child {
    width: 15%;
}
table .radio-button-inside {
    margin-left: 2rem;
}
</style>

<style lang="scss">
#build-details-form .v-select__selections {
    min-height: 40px;
    overflow: hidden;
}
</style>


